import { useParams } from 'react-router-dom'
import Data from './SoftwareDevelopmentData.json'
import './SoftwareDevelopment.css'

import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import UnderconstructionComponent from '../../components/PageUnderconstruction/Underconstruction'

function SoftwareDevelopment() {
  let params = useParams()
  let data_lang,
    data_both = Data.both

  //choose language
  if (params.language === 'en') {
    data_lang = Data.en
  } else {
    data_lang = Data.th
  }

  const [modalShow, setModalShow] = useState(false)
  const [modalData, setModalData] = useState(null)

  function handleShow(data) {
    setModalData(data)
    setModalShow(true)
  }

  if (params.language === 'th')
    return (
      <>
        <div className="sofware-dev-header p-5">
          <Container>
            <Row className="px-3 py-4">
              <Col md={7}>
                <h1 className="text-center">{data_both.topic}</h1>
              </Col>
              <Col md={5} className="text-center">
                <img
                  src="/images/software-development-cover.svg"
                  className="sofware-dev-cover mt-4 mt-md-0"
                  alt="Software Development Cover"
                />
              </Col>
            </Row>
            <div className="color-line color-line-blue-green"></div>
          </Container>
        </div>

        <CardProject
          projects={data_lang.projects}
          handleShow={handleShow}
          cardTopic={data_lang.card_topic}
        />
        <ModalProject
          modalData={modalData}
          modalShow={modalShow}
          setModalShow={setModalShow}
          modalTopic={data_lang.modal_topic}
        />
      </>
    )
  else {
    return (
      <>
        <UnderconstructionComponent
          data={{
            topic: data_both.topic,
            announcement: data_lang.announcement,
          }}></UnderconstructionComponent>
      </>
    )
  }
}

function CardProject({ projects, handleShow, cardTopic }) {
  if (projects !== undefined) {
    return projects.map((data) => {
      return (
        <Container key={data.name} className="mb-4">
          <Card className="jl-card software-dev-card border-0 p-4">
            <h4 className="sofware-dev-card-topic mt-1 mb-2 px-3">
              {data.name}
            </h4>
            <div className="software-dev-card-detail px-3">
              <p className="software-dev-card-detail-year color-blue mb-1">
                {cardTopic.year} {data.year}
              </p>
              <p className="software-dev-card-detail-description my-2 color-gray">
                &nbsp;&nbsp;&nbsp;{data.description}
              </p>
              <Container fluid>
                <Row>
                  <Col md={8} lg={9} className="p-0">
                    <p className="software-dev-card-detail-tool mb-0">
                      {cardTopic.tool}
                      {data.tool}
                    </p>
                  </Col>
                  <Col md={4} lg={3} className="py-md-0 ps-md-4 pe-md-0">
                    <Button
                      onClick={() => {
                        handleShow(data)
                      }}
                      variant="outline-secondary"
                      className="software-dev-card-button mt-3 mt-md-1">
                      <p className="d-inline">See more</p>{' '}
                      <i className="bi bi-arrow-right mt-2"></i>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Card>
        </Container>
      )
    })
  }
}

function ModalProject({ modalData, modalShow, setModalShow, modalTopic }) {
  if (modalShow) {
    return (
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="px-2 py-1">{modalData.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="software-dev-modal-body px-3 py-1">
            <p>
              <b>{modalTopic.year} : </b>
              {modalData.year}
            </p>
            <p>
              <b>{modalTopic.tool} : </b>
              {modalData.tool}
            </p>
            <p className="my-3">
              <b>{modalTopic.description} : </b> {modalData.description}
            </p>
            <ul className="pe-3">
              {modalData.detail.map((data) => {
                if (typeof data === 'object')
                  return (
                    <>
                      <li>{data.subtopic}</li>
                      <ul>
                        {data.detail.map((detail) => {
                          return <li>{detail}</li>
                        })}
                      </ul>
                    </>
                  )
                // if (typeof data === "string")
                else return <li>{data}</li>
              })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default SoftwareDevelopment
