import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { createHashRouter, RouterProvider } from "react-router-dom";

import Root from "./Root";

import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Engineering from "./pages/Engineering/Engineering";
import SoftwareDevelopment from "./pages/SoftwareDevelopment/SoftwareDevelopment";

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: ":language",
        element: <Home />,
      },
      {
        path: "about-us/:language",
        element: <AboutUs />,
      },
      {
        path: "contact/:language",
        element: <Contact />,
      },
      {
        path: "engineering/:language",
        element: <Engineering />,
      },
      {
        path: "software-development/:language",
        element: <SoftwareDevelopment />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
