import { useParams } from 'react-router-dom'
import Data from './AboutUsData.json'
import './AboutUs.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

function AboutUs() {
  let params = useParams()
  let data_lang,
    data_both = Data.both

  //choose language
  if (params.language === 'en') {
    data_lang = Data.en
  } else {
    data_lang = Data.th
  }

  return (
    <>
      <div className="about-us-header pt-5 pb-4">
        <Container>
          <h1>{data_both.topic}</h1>
          <p className="about-us-sub-topic">{data_both.subtopic}</p>
          <div className="color-line color-line-purple-blue-purple"></div>
          <p className="about-us-introduction mt-4">{data_lang.introduction}</p>
        </Container>
      </div>
      <Container className="about-us-information py-5">
        <Row>
          {data_lang.information.map((info) => {
            return <p>{info}</p>
          })}
        </Row>
        <Row className="mt-3">
          <a className="about-us-link" href={data_both.link_pdf_download}>
            Download PDF File
          </a>
        </Row>
      </Container>
    </>
  )
}

export default AboutUs
