import { useParams } from 'react-router-dom'
import Data from './ContactData.json'
import './Contact.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

function Contact() {
  let params = useParams()
  let data_lang,
    data_both = Data.both

  //choose language
  if (params.language === 'en') {
    data_lang = Data.en
  } else {
    data_lang = Data.th
  }

  return (
    <>
      <Container className="py-4">
        <Row>
          <img
            src="/images/jlcontrol-map.png"
            className="contact-map d-inline-block align-center"
            alt="JL Control map"
          />
        </Row>
        <Row className="mt-5">
          <h1 className="color-blue mb-4">{data_both.topic}</h1>
          <Container className="px-4">
            <Row>
              <div className="contact-detail color-blue">
                <i className="bi bi-geo-alt-fill pt-1"></i>
                <h4 className="mx-2">Location</h4>
              </div>
              {data_lang.location.map((info) => {
                return <p className="my-1 mx-4">{info}</p>
              })}
              <a
                href={data_both.link_location}
                className="mx-4"
                target="_blank"
                rel="noreferrer">
                see location <i className="bi bi-arrow-right"></i>{' '}
              </a>
            </Row>
            <Row className="mt-4">
              <div className="contact-detail color-blue">
                <i className="bi bi-telephone-fill pt-1"></i>
                <h4 className="mx-2">Phone</h4>
              </div>
              <p className="mx-4">{data_both.telephone}</p>
            </Row>
            <Row className="mt-4">
              <div className="contact-detail color-blue">
                <i className="bi bi-envelope-fill pt-1"></i>
                <h4 className="mx-2">Email</h4>
              </div>
              <p className="mx-4">{data_both.email}</p>
            </Row>
            <Row className="mt-4">
              <div className="contact-detail color-blue">
                <i className="bi bi-clock-fill pt-1"></i>
                <h4 className="mx-2">Working hour</h4>
              </div>
              {Object.entries(data_both.working_hour).map(([key, val]) => {
                return (
                  <p className="mx-4">
                    {key} : {val}
                  </p>
                )
              })}
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  )
}

export default Contact
