import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "bootstrap/dist/css/bootstrap.min.css";

//for footer
import Data from "./RootData.json";
import HomeData from "./pages/Home/HomeData.json";
import { useParams } from "react-router-dom";

import "./Root.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Root() {
  return (
    <>
      <NavigationBar />
      <div className="main-section">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

//navigstion bar
function NavigationBar() {
  const navigate = useNavigate();

  const [language, setLanguage] = useState("th");
  const [classTH, setClassTH] = useState("outline-secondary");
  const [classEN, setClassEN] = useState("outline-secondary");

  let fullLocation = useLocation().pathname;
  let location = fullLocation.slice(0, -2);
  let activeLanguage = fullLocation.slice(-2);

  useEffect(() => {
    // when visit for the first time ('/') -> redirect to home page th ('/th')
    if (activeLanguage !== "th" && activeLanguage !== "en") {
      if (fullLocation.slice(-1) === "/") navigate(fullLocation + "th");
      else navigate(fullLocation + "/th");
    }

    setLanguage(activeLanguage);

    if (language === "en") {
      setClassTH("outline-secondary");
      setClassEN("secondary");
    } else {
      setClassTH("secondary");
      setClassEN("outline-secondary");
    }
  }, [activeLanguage, language, navigate]);

  const changeLanguageTo = (language) => (event) => {
    setLanguage(language);

    if (language === "en") {
      setClassTH("outline-secondary");
      setClassEN("secondary");
    } else {
      setClassTH("secondary");
      setClassEN("outline-secondary");
    }

    // Use to refresh page when changing language
    navigate(location + language);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container className="px-5">
        <Navbar.Brand href={"/#/" + language}>
          <img
            src="/images/jlcontrol-logo.png"
            className="d-inline-block align-center me-2"
            height="20"
            alt="JL Control logo"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* the left side menubar */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={"/#" + location + language}
            variant="underline"
          >
            {/* call function that loop all pagelist */}
            <NavigationMenu language={language} margin="0" />
          </Nav>
        </Navbar.Collapse>

        {/* the right side language menu */}
        <Navbar.Collapse className="justify-content-end mt-2 mb-3 my-lg-0">
          {/* call function that change button */}
          <ButtonGroup>
            <Button onClick={changeLanguageTo("th")} variant={classTH}>
              TH
            </Button>
            <Button onClick={changeLanguageTo("en")} variant={classEN}>
              EN
            </Button>
          </ButtonGroup>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

// navigation menu
function NavigationMenu({ language, margin }) {
  return Data.pagelists.map((page) => {
    let path = "/#" + page.path + "/" + language;
    let marginBottom = margin;
    return (
      <Nav.Link
        href={path}
        key={page.path}
        className="navigation-bar-menu me-1"
        style={{ marginBottom }}
      >
        {page.name}
      </Nav.Link>
    );
  });
}

//footer
function Footer() {
  let params = useParams();
  let homeData_lang;

  //choose language
  if (params.language === "en") {
    homeData_lang = HomeData.en;
  } else {
    homeData_lang = HomeData.th;
  }

  return (
    <>
      <div className="footer">
        <Container className="py-4 px-3">
          <Row>
            <Col lg={6} className="mb-4">
              <img
                src="/images/jlcontrol-logo.png"
                className="d-inline-block align-center"
                height="50"
                alt="JL Control logo"
              ></img>
              <p className="mt-3">{homeData_lang.slogan}</p>
              <a
                className="mt-5 footer-detail color-gray"
                href={Data.link_terms_of_service}
              >
                term of service
              </a>
              <p className="mt-3 footer-detail color-gray">
                Copyright © 2023 by JL Control co., ltd
              </p>
            </Col>
            <Col>
              <h4>Menu</h4>
              <NavigationMenu language={params.language} margin={3} />
            </Col>
            <Col>
              <h4>Contact</h4>
              <p className="mt-3 footer-detail color-gray">
                sales@jlcontrol.com
              </p>
              <p className="footer-detail color-gray">02-996-6655-6</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Root;
