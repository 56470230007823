import { useParams } from "react-router-dom";
import Data from "./HomeData.json";
import DataContact from "../Contact/ContactData.json";
import "./Home.css";

//import react bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function Home() {
  let params = useParams();
  let contact_path = "/#/contact/" + params.language;
  let data_lang,
    data_both = Data.both;
  let data_contact_lang,
    data_contact_both = DataContact.both;

  //choose language
  if (params.language === "en") {
    data_lang = Data.en;
    data_contact_lang = DataContact.en;
  } else {
    data_lang = Data.th;
    data_contact_lang = DataContact.th;
  }

  return (
    <>
      {/* this is for topic and header ----------------------------------------------------------------------*/}
      <div className="home-topic">
        <Container fluid className="p-5">
          <div>
            <img
              src="/images/jlcontrol-logo-outline.png"
              className="align-center mt-2"
              alt="JL Control logo"
            ></img>
            <h5 className="mt-3 mb-0">{data_lang.slogan}</h5>
          </div>
        </Container>
      </div>

      {/* this is for service ----------------------------------------------------------------------*/}
      <Container className="mt-5">
        <div className="mb-3">
          <a className="home-all-head color-black">
            {data_both.topic_our_service_1}{" "}
          </a>
          <a className="home-all-head color-blue">
            {data_both.topic_our_service_2}
          </a>
        </div>
        <Row>
          <Col lg className="d-flex align-items-stretch mb-2">
            <Card className="jl-card border-0">
              <Container fluid>
                <h4 className="home-card-head mb-1">
                  {data_both.topic_engineering}
                </h4>
                <div className="color-line color-line-purple-blue"></div>
                <Row>
                  <Col>
                    <p className="home-card-p">
                      {data_lang.summary_engineering}
                    </p>
                  </Col>
                  <Col className="text-center">
                    <img
                      src="/images/engineering-cover.svg"
                      className="home-card-img"
                      alt="Engineering Cover"
                    ></img>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
          <Col lg className="d-flex align-items-stretch mb-2">
            <Card className="jl-card border-0">
              <Container fluid>
                <h4 className="home-card-head mb-1">
                  {data_both.topic_software_development}
                </h4>
                <div className="color-line color-line-blue-green"></div>
                <Row>
                  <Col>
                    <p className="home-card-p">
                      {data_lang.summary_software_development}
                    </p>
                  </Col>
                  <Col className="text-center">
                    <img
                      src="/images/software-development-cover.svg"
                      className="home-card-img"
                      alt="Software Development Cover"
                    ></img>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* this is for about us ----------------------------------------------------------------------*/}
      <Container className="mt-5">
        <Row>
          <h1 className="home-all-head text-center color-blue">About Us</h1>
          <div className="color-line color-line-sky-blue-sky"></div>
        </Row>
        <Row>
          <p className="text-center">{data_lang.summary_about_us}</p>
        </Row>
      </Container>

      {/* this is for contact ----------------------------------------------------------------------*/}
      <Container className="mt-5 mb-5">
        <Row>
          <h1 className="home-all-head color-blue">Contact</h1>
        </Row>
        <Row className="mt-3">
          <Col lg={6} className="mb-3">
            <img
              src="/images/jlcontrol-map.png"
              className="home-contact-img"
              alt="JL Control map"
            ></img>
          </Col>
          <Col>
            {/* I copied this part from contact page */}
            <Container>
              <Row>
                <div className="contact-detail">
                  <i className="bi bi-geo-alt-fill pt-1 color-blue"></i>
                  <p className="mx-4">
                    {data_contact_lang.location[0]}
                    {data_contact_lang.location[1]}
                  </p>
                </div>
              </Row>

              <Row className="mt-2">
                <div className="contact-detail">
                  <i className="bi bi-telephone-fill pt-1 color-blue"></i>
                  <p className="mx-4">{data_contact_both.telephone}</p>
                </div>
              </Row>

              <Row className="mt-2">
                <div className="contact-detail">
                  <i class="bi bi-envelope-fill pt-1 color-blue"></i>
                  <p className="mx-4">{data_contact_both.email}</p>
                </div>
              </Row>

              <Button
                // onClick={() => {
                //   navigate(contact_path)
                // }}
                href={contact_path}
                variant="outline-secondary"
                className="home-contact-button px-4 mt-3"
              >
                <p className="d-inline">More Contact</p>{" "}
                <i className="bi bi-arrow-right-circle-fill mt-2"></i>
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>

      {/* just box -------------------------------------------------------------------- */}
      {/* <Container className="mt-2 mb-5 home-bottom-card">
        <Row>
          <Col lg className="align-items-stretch mb-2">
            <Card className="jl-card border-0 bottom-card-color-green-blue">
              <p>I Don't know why but it BEAUTIFUL!!!</p>
            </Card>
          </Col>
          <Col lg className="align-items-stretch mb-2">
            <Card className="jl-card border-0 bottom-card-color-blue-sky">
              <p>I Don't know why but it BEAUTIFUL!!!</p>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}

export default Home;
