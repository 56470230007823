import "./Underconstruction.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Underconstruction({ data }) {
  return (
    <>
      <Container className="under-construction my-5">
        <Row>
          <Col lg>
            <img src="/images/under_construction.svg" alt="Underconstruction" />
          </Col>
          <Col lg className="text-center">
            <p className="head color-blue">{data.topic}</p>
            <p className="announce">{data.announcement}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
