import { useParams } from 'react-router-dom'
import Data from './EngineeringData.json'
import './Engineering.css'

import UnderconstructionComponent from '../../components/PageUnderconstruction/Underconstruction'

function Engineering() {
  let params = useParams()
  let data_lang,
    data_both = Data.both

  //choose language
  if (params.language === 'en') {
    data_lang = Data.en
  } else {
    data_lang = Data.th
  }

  return (
    <>
      <UnderconstructionComponent
        data={{ topic: data_both.topic, announcement: data_lang.announcement }}
      />
    </>
  )
}

export default Engineering
